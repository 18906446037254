import "./Countdown.css";

function Countdown() {
  return (
    <div>
      <iframe
        src="https://dev-8mksvy7wq-brkozcn.vercel.app/"
        frameborder="0"
        width={"100%"}
        height={"100%"}
        mar
      />
    </div>
  );
}

export default Countdown;
