import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Text,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";

function Block() {
  return (
    <>
      <Card maxW="sm">
        <CardBody>
          <Image
            src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png"
            alt="Green double couch with wooden legs"
            borderRadius="lg"
          />
          <Stack mt="6" spacing="3">
            <Heading size="md">Google</Heading>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}

export default Block;
