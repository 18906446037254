import Tabs from "./pages/Tabs";
import Panel from "./pages/Panel";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Countdown from "./pages/Countdown";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Countdown />} />
        </Routes>
      </BrowserRouter>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/tabs" element={<Tabs />} />
            <Route path="tabs/panel" element={<Panel />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
