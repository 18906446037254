import React from "react";
import Block from "../components/Block";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Tabs() {
  return (
    <>
      <Flex float={"right"}>
        <Link to="panel">Edit</Link>
      </Flex>
      <Flex>
        <Block />
      </Flex>
    </>
  );
}

export default Tabs;
